<template>
  <div id="email-list" v-cloak v-loading.fullscreen.lock="loading">
    <el-row class="email-row-0">
      <el-col :span="2" class="email-row-0-label">
        &nbsp;
        <span v-if="boxName == 'folderBox'"
          >{{ $t("emailList.folder") }}：</span
        >
      </el-col>
      <el-col :span="10">
        &nbsp;
        <el-select
          v-if="boxName == 'folderBox'"
          size="mini"
          v-model="nowFolder"
          @change="changeFolder"
          ref="folderBox"
        >
          <el-option
            v-for="folder in folderList"
            :value="folder.id"
            :label="folder.name"
            :key="folder.id"
          ></el-option>
        </el-select>
        <span class="email-row-0-icon" v-if="boxName == 'folderBox'">
          <el-popover
            placement="bottom"
            width="250"
            trigger="click"
            @show="doFolderIcon('edit')"
            v-if="nowFolder"
          >
            <el-input v-model="newName" size="mini">
              <el-button
                slot="append"
                size="mini"
                @click="doFolderIcon('submit', nowFolder, newName)"
                >{{ $t("confirm") }}</el-button
              >
            </el-input>
            <i class="el-icon-edit-outline" slot="reference"></i>
          </el-popover>
          <i
            class="el-icon-minus"
            @click="doFolderIcon('delete', nowFolder)"
            v-if="nowFolder"
          ></i>
          <el-popover
            placement="bottom"
            width="250"
            trigger="click"
            @show="doFolderIcon('add')"
          >
            <el-input
              v-model="newNameNew"
              size="mini"
              :placeholder="$t('emailList.text')"
            >
              <el-button
                slot="append"
                size="mini"
                @click="doFolderIcon('submit', 0, newNameNew)"
                >{{ $t("confirm") }}</el-button
              >
            </el-input>
            <i class="el-icon-plus" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="email-row-1">
        <div class="email-heard">
          <el-select
            class="duan"
            size="mini"
            v-model="search.isFlag"
            :placeholder="$t('emailList.star')"
          >
            <el-option value="1" :label="$t('emailList.star')"></el-option>
            <el-option value="0" :label="$t('emailList.noStar')"></el-option>
          </el-select>
          <el-select
            class="duan"
            size="mini"
            v-model="search.isSeen"
            :placeholder="$t('emailList.read')"
          >
            <el-option value="1" :label="$t('emailList.read')"></el-option>
            <el-option value="0" :label="$t('emailList.unread')"></el-option>
          </el-select>
          <el-select
            class="duan"
            size="mini"
            v-model="search.isAtta"
            :placeholder="$t('taskInfo.attachment')"
          >
            <el-option value="1" :label="$t('emailList.text1')"></el-option>
            <el-option value="0" :label="$t('emailList.text2')"></el-option>
          </el-select>
          <el-date-picker
            class="el-date-picker-email"
            v-model="search.date"
            type="daterange"
            value-format="yyyy-MM-dd"
            size="mini"
            :start-placeholder="$t('contactList.startDate')"
            :end-placeholder="$t('contactList.endDate')"
          >
          </el-date-picker>
          <el-input
            class="search-email"
            v-model="search.key"
            size="mini"
            :placeholder="$t('emailList.text')"
          ></el-input>
          <el-button type="primary" size="mini" @click="getEmailList">{{
            $t("search")
          }}</el-button>
        </div>
      </el-col>
    </el-row>

    <el-row class="email-row-2" v-if="selectedId.length > 0">
      <el-col :span="24">
        <div class="email-heard-1">
          <span class="selectedX"
            >{{ $t("taskManagement.selected") }}{{ selectedId.length
            }}{{ $t("emailList.rows") }}</span
          >
          <el-select
            size="mini"
            v-model="select.flag"
            :placeholder="$t('emailList.marked')"
            @change="changeFlagMove($event, 'flag')"
          >
            <el-option value="is_seen">{{ $t("emailList.read") }}</el-option>
            <el-option value="no_seen">{{ $t("emailList.unread") }}</el-option>
            <el-option value="is_flag">{{ $t("emailList.star") }}</el-option>
            <el-option value="no_flag">{{
              $t("emailList.cancelStar")
            }}</el-option>
          </el-select>
          <el-select
            v-if="boxName != '!cus_sendBox' && boxName != '!cus_rubbishBox'"
            size="mini"
            v-model="select.move"
            :placeholder="$t('emailList.moveTo')"
            @change="changeFlagMove($event, 'move')"
          >
            <el-option v-if="boxName != '!cus_inBox'" value="!cus_inBox">{{
              $t("emailList.inbox")
            }}</el-option>
            <el-option
              v-if="boxName != '!cus_rubbishBox'"
              value="!cus_rubbishBox"
              >{{ $t("emailList.trash") }}</el-option
            >
            <el-option
              v-for="(folder, index) in folderList.filter((f) => {
                if (nowFolder != f.id) return f;
              })"
              :value="folder.id"
              :label="folder.name"
              :key="index"
            ></el-option>
          </el-select>
          <el-button
            type="warning"
            size="mini"
            v-if="boxName != '!cus_rubbishBox'"
            @click="changeFlagMove('!cus_rubbishBox', 'move')"
            >{{ $t("emailList.delete") }}</el-button
          >
          <el-button
            type="success"
            size="mini"
            v-if="boxName == '!cus_rubbishBox'"
            @click="changeFlagMove('!cus_return', 'move')"
            >{{ $t("emailList.restore") }}</el-button
          >
          <el-button
            type="danger"
            size="mini"
            @click="deleteTrue('!cus_rubbishBox', 'delete')"
            >{{ $t("emailList.delete1") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row class="email-row-3">
      <el-col :span="3">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="changeSelectAll"
          >{{ $t("email.all") }}</el-checkbox
        >
      </el-col>
      <el-col :span="6" v-if="boxName == '!cus_sendBox'">{{
        $t("emailList.to")
      }}</el-col>
      <el-col :span="6" v-else>{{ $t("emailList.from") }}</el-col>
      <el-col :span="6">{{ $t("emailList.subject") }}</el-col>
      <el-col
        :span="2"
        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
        >{{ $t("emailList.views") }}</el-col
      >
      <el-col :span="5">{{ $t("emailList.time") }}</el-col>
    </el-row>

    <div
      v-for="(emailInfo, emailIndex) in emailList"
      style="min-width: 1000px"
      :key="emailIndex"
    >
      <el-row v-if="emailInfo.showDay" class="email-row-4">
        <el-col :span="24"
          ><span class="email-day">{{ emailInfo.showDay }}</span
          >({{ emailInfo.dayTime }})</el-col
        >
      </el-row>
      <el-row
        class="email-row-5"
        :class="[emailInfo.is_flag ? 'email-row-5-flag' : '']"
      >
        <el-col :span="3">
          <el-checkbox
            :key="emailInfo.id"
            label=" "
            v-model="checkedEmails[emailIndex]"
            @change="changeSelect"
            >&nbsp;</el-checkbox
          >
          <i
            :class="[
              emailInfo.is_seen
                ? 'el-icon-chat-round'
                : 'el-icon-chat-dot-round',
            ]"
            @click="oneClick('seen', emailInfo)"
          ></i>
          <i
            class="el-icon-paperclip"
            v-if="emailInfo.is_Attachments"
            @click="goToEmailInfo(emailInfo)"
          ></i>
        </el-col>
        <el-col :span="6" :class="[emailInfo.is_seen ? '' : 'cu']"
          ><div
            @click="goToEmailInfo(emailInfo)"
            class="short emailInfo-to-name"
          >
          <!-- 这里有一个 bug，后端会返回空的收件人字段。 -->
            &nbsp;{{ emailInfo.to_email || $t('emailList.emptyToEmail') }}
          </div></el-col
        >
        <el-col :span="6" :class="[emailInfo.is_seen ? '' : 'cu']"
          ><div
            @click="goToEmailInfo(emailInfo)"
            class="short emailInfo-to-name"
          >
            &nbsp;{{ emailInfo.subject || $t('emailList.empty') }}
          </div></el-col
        >
        <el-col :span="2" :class="[emailInfo.is_seen ? '' : 'cu']"
          ><div
            @click="goToEmailInfo(emailInfo)"
            class="short emailInfo-to-name"
          >
            &nbsp;{{ emailInfo.view_num }}
          </div></el-col
        >
        <el-col :span="5">
          {{ emailInfo.date }}
        </el-col>
        <el-col :span="2">
          <i
            class="icon-right el-icon-s-promotion"
            @click="returnEmail('zhuanFa', emailInfo)"
          ></i>
          <i
            class="icon-right el-icon-phone-outline"
            @click="returnEmail('huiFu', emailInfo)"
          ></i>
          <i
            class="icon-right"
            :class="[
              emailInfo.is_flag ? 'el-icon-star-on' : 'el-icon-star-off',
            ]"
            @click="oneClick('flag', emailInfo)"
          ></i>
        </el-col>
      </el-row>
    </div>

    <el-pagination
      @size-change="pageChange()"
      @current-change="pageChange()"
      :current-page.sync="page"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total.sync="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { toMessage } from "@/utils/message";
export default {
  name: "EmailList",
  data() {
    return {
      loading: true,
      emailList: [],
      //文件夹相关
      folderList: [],
      nowFolder: "",
      newName: "",
      newNameNew: "",
      //搜索条件
      search: {
        date: ["", ""],
        key: "",
        isFlag: "",
        isSeen: "",
        isAtta: "",
      },
      //select下拉
      select: {
        move: "",
        flag: "",
      },
      //多选框控制
      checkedEmails: [],
      checkAll: false,
      isIndeterminate: false,
      //分页
      total: 0,
      page: 1,
      pageSize: 20,
      //当前所在菜单类型 '!cus_inBox','!cus_sendBox','folderBox','!cus_rubbishBox'
      // boxName: "{$boxName}",
      boxName: "",
    };
  },
  computed: {
    //已经选中的id
    selectedId() {
      let selected = [];
      this.emailList.forEach((item, index) => {
        if (this.checkedEmails[index]) {
          selected.push(item.id);
        }
      });
      return selected;
    },
  },
  methods: {
    /**
     * 获取用户自定义文件夹
     */
    getFolderList() {
      return new Promise((resolve) => {
        this.axios
          .post("/home/CrmEmail/getFolderList")
          .then((res) => res.data)
          .then((res) => {
            this.loading = false;
            if (res.status != 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            this.folderList = res.data;
            resolve(this.folderList);
          });
      });
    },
    pageChange() {
      this.checkedEmails = [];
      this.changeSelect();
      this.getEmailList();
    },
    /**
     * 根据参数获取各类邮件列表、收件箱、发件箱、文件夹等
     */
    getEmailList() {
      let boxName = this.boxName;
      if (boxName == "folderBox") {
        boxName = this.nowFolder;
      }
      this.loading = true;
      this.axios
        .post("/home/CrmEmail/getEmailList", {
          boxName: boxName,
          key: this.search.key,
          date: this.search.date,
          isFlag: this.search.isFlag,
          isSeen: this.search.isSeen,
          isAtta: this.search.isAtta,
          page: this.page,
          pageSize: this.pageSize,
        })
        .then((res) => res.data)
        .then((res) => {
          if (res.status != 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.changeSelect();
          this.loading = false;
          this.total = res.data.total;
          this.emailList = this.dealRes(res.data.data);
        });
    },
    /**
     * 处理数据（设置今天，昨天，过去）
     * 发件项解析json
     */
    dealRes(data) {
      let today = new Date(new Date().toLocaleDateString()).getTime();
      let yesterday = new Date(today - 1000 * 60 * 60 * 24).getTime();

      let showTodayIndex = -1;
      let showYesterdayIndex = -1;
      let showAgoIndex = -1;
      data.forEach((item, index) => {
        //解析json
        if (item.is_sent == 1) {
          let temp = item.to_email;
          try {
            let toEmail = JSON.parse(item.to_email);
            item.to_email = Object.keys(toEmail).join(" , ");
          } catch (err) {
            item.to_email = temp;
          }
        }

        if (new Date(item.date).getTime() >= today) {
          if (showTodayIndex >= 0) {
            data[showTodayIndex].dayTime++;
            return;
          }
          item.showDay = "today";
          item.dayTime = 1;
          showTodayIndex = index;
          return;
        }
        if (new Date(item.date).getTime() >= yesterday) {
          if (showYesterdayIndex >= 0) {
            data[showYesterdayIndex].dayTime++;
            return;
          }
          item.showDay = "yesterday";
          item.dayTime = 1;
          showYesterdayIndex = index;
          return;
        }
        if (showAgoIndex >= 0) {
          data[showAgoIndex].dayTime++;
          return;
        }
        item.showDay = "ago";
        item.dayTime = 1;
        showAgoIndex = index;
      });
      return data;
    },
    /**
     * 修改选择
     */
    changeSelect() {
      let checkedCount = this.selectedId.length;
      this.checkAll =
        checkedCount === this.emailList.length && checkedCount != 0;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.emailList.length;
    },
    /**
     * 修改全选
     */
    changeSelectAll(type) {
      this.checkAll = type;
      this.isIndeterminate = false;
      this.emailList.forEach((item, index) => {
        this.$set(this.checkedEmails, index, type);
      });
    },
    /**
     * 单击icon触发
     */
    oneClick(type, emailInfo) {
      if (type == "seen") {
        this.changeFlagMove(emailInfo.is_seen ? "no_seen" : "is_seen", "flag", [
          emailInfo.id,
        ]);
      } else {
        this.changeFlagMove(emailInfo.is_flag ? "no_flag" : "is_flag", "flag", [
          emailInfo.id,
        ]);
      }
    },
    /**
     * 触发下拉移动文件夹等事件
     */
    changeFlagMove(value, bind, selectedId = []) {
      this.select[bind] = "";
      this.loading = true;
      this.axios
        .post("/home/CrmEmail/dealBind", {
          selectedId: selectedId.length != 0 ? selectedId : this.selectedId,
          bind: bind,
          value: value,
        })
        .then((res) => res.data)
        .then((res) => {
          switch (res.status) {
            case 1:
              this.$message(toMessage());
              break;
            case 2:
              this.$message(
                toMessage("warning", res.data + this.$t("emailList.warning"))
              );
              break;
            case 3:
              this.$message(
                toMessage("warning", res.data + this.$t("emailList.warning1"))
              );
              break;
            default:
              this.$message(toMessage("error", res.msg));
              return;
          }
          this.getEmailList();
          if (bind == "move" || bind == "delete") {
            this.checkedEmails = [];
            this.changeSelect();
          }
        });
    },
    addTab(option = "Index", action = "welCome", name = "首页", param) {
      let url = "/" + option + "/" + action;
      console.log(name);
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      this.$router.push(url);
    },
    /**
     * 转发回复email
     */
    returnEmail(returnType, emailInfo) {
      this.addTab("CrmEmail", "writeEmail", "<tags:lang>写信</tags:lang>", {
        returnType: returnType,
        emailInfoId: emailInfo.id,
      });
    },
    /**
     * 彻底删除
     */
    deleteTrue(value, bind) {
      this.$confirm(this.$t("emailList.text3"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.changeFlagMove(value, bind);
        })
        .catch(() => {});
    },
    /**
     * 切换文件夹
     */
    changeFolder() {
      for (let i in this.folderList) {
        if (this.folderList[i].id == this.nowFolder) {
          this.newName = this.folderList[i].name;
          break;
        }
      }
      this.checkedEmails = [];
      this.getEmailList();
      setTimeout(() => {
        this.changeSelect();
      }, 500);
    },
    /**
     * 文件夹icon操作
     */
    doFolderIcon(icon, nowFolder = 0, newName = "") {
      switch (icon) {
        case "delete":
          this.$confirm(this.$t("emailList.text4"), this.$t("tips"), {
            confirmButtonText: this.$t("confirm"),
            cancelButtonText: this.$t("cancel"),
            type: "warning",
          })
            .then(() => {
              this.ajaxFolderIcon(icon, nowFolder, "delete").then(() => {
                this.getFolderList().then((resolve) => {
                  this.nowFolder = resolve.length == 0 ? "" : resolve[0].id;
                  if (this.nowFolder) {
                    this.getEmailList();
                  }
                });
              });
            })
            .catch(() => {});
          break;

        case "edit":
          for (let i in this.folderList) {
            if (this.folderList[i].id == this.nowFolder) {
              this.newName = this.folderList[i].name;
              break;
            }
          }
          break;
        case "add":
          this.newNameNew = "";
          break;
        case "submit":
          icon = nowFolder ? "edit" : "add";
          if (newName == "") {
            return;
          }
          this.ajaxFolderIcon(icon, nowFolder, newName).then(() => {
            this.getFolderList().then((resolve) => {
              if (icon == "add") {
                this.nowFolder = resolve[resolve.length - 1].id;
                this.emailList = [];
              }
            });
          });
      }
    },
    /**
     * 文件夹icon操作
     */
    ajaxFolderIcon(icon, nowFolder = 0, newName = "") {
      return new Promise((resolve) => {
        this.axios
          .post("/home/CrmEmail/ajaxFolderIcon", {
            icon: icon,
            nowFolder: nowFolder,
            newName: newName,
          })
          .then((res) => res.data)
          .then((res) => {
            if (res.status != 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            this.$message(toMessage());
            resolve(nowFolder);
          });
      });
    },
    /**
     * 跳转到详情页
     */
    goToEmailInfo(emailInfo) {
      this.addTab("CrmEmail", "emailInfo", "<tags:lang>邮件详情</tags:lang>", {
        emailInfoId: emailInfo.id,
      });
    },
  },
  watch: {
        $route: function (to, from) {
      if (to !== from) {
        location.reload();
      }
    },
  },
  created() {
    const routeName = this.$route.name;
    //当前所在菜单类型 '!cus_inBox','!cus_sendBox','folderBox','!cus_rubbishBox'
    if (routeName == "Inbox") {
      this.boxName = "!cus_inBox";
    } else if (routeName == "SendBox") {
      this.boxName = "!cus_sendBox";
    } else if (routeName == "Folder" ) {
      this.boxName = "folderBox";
    } else {
      this.boxName = "!cus_rubbishBox";
    }
    this.getFolderList().then((resolve) => {
      if (this.boxName == "folderBox") {
        if (resolve.length == 0) {
          this.$message(toMessage("warning", this.$t("emailList.warning2")));
          return;
        }
        this.nowFolder = resolve[0].id;
      }
      this.getEmailList();
    });
  },
};
</script>

<style scoped>
#email-list {
  color: #606266;
  padding-right: 28px !important;
  padding-left: 28px !important;
  padding-bottom: 28px !important;
  height: 100%;
  overflow: auto;
}
.email-row-0-label {
  text-align: right;
  line-height: 30px;
}

.email-row-0 .email-row-0-icon i {
  font-size: 18px;
  margin-right: 5px;
  border: 1px dashed #81cef5;
}
.email-row-1,
.email-row-0 {
  height: 50px;
}
.email-row-2 {
  height: 30px;
}
.email-row-3,
.email-row-5 {
  font-size: 14px;
  line-height: 40px;
  border-bottom: 1px solid #e5e7e8;
}
.short {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.email-heard {
  position: absolute;
  right: 20px;
}
.el-range-editor--mini,
.el-date-picker-email {
  width: 250px;
}
.search-email {
  width: 200px !important;
}
.selectedX {
  line-height: 30px;
  margin-right: 20px;
}
.email-row-5 i {
  font-size: large;
}
.email-row-5-flag {
  background: #faecea;
}
.el-icon-star-on {
  color: #ee3d3d;
}
.el-icon-chat-dot-round {
  color: #ee3d3d;
}
.cu {
  font-weight: bold;
  color: #173145;
}
.duan {
  width: 120px;
}
.el-pagination {
  margin-top: 20px;
}
.email-row-4 {
  height: 40px;
  line-height: 40px;
  border-bottom: 2px solid #babcbd;
}
.email-day {
  font-weight: bolder;
  font-size: larger;
}
.emailInfo-to-name {
  width: 80%;
}
</style>